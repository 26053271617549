/**
 * Trigger Animation Restart
 * @see https://css-tricks.com/restart-css-animation/
 *
 * @param {Element} element
 * @param {string} animationAttribute Attribute with the associated animation
 */
const triggerAnimationRestart = (element, attributeKey, attributeValue) => {
    element.removeAttribute(attributeKey);
    // eslint-disable-next-line no-void
    void element.offsetWidth;
    element.setAttribute(attributeKey, attributeValue);
};

export default triggerAnimationRestart;
